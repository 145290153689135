import '@babel/polyfill'
import 'mutationobserver-shim'

import Vue from 'vue'


// Configuraciones generales Vue
Vue.config.productionTip = false


// Moment.js para manipulacion fechas
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
    moment
})


// Formateo y filtrado de numeros usando Numeral.js
import vueNumeralFilterInstaller from 'vue-numeral-filter'

Vue.use(vueNumeralFilterInstaller, { locale: 'es' })


// Barra de progreso común para cambios de página (ruta) y peticiones http
import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  thickness: '6px',
  autoFinish: false
})


// Router y rutas
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import routes from './routes'

var router = new VueRouter({
  routes,
  mode: 'history'
})


// Registro global de Vue-Bootstrap y sus íconos
import './plugins/bootstrap-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


// Variable global this.$api para biblioteca http axios
import axios from 'axios'
const url_base = Vue.config.devtools ?
    "http://localhost:1086/api/v1" :
    "https://api.conpax.cl/api/v1"
Vue.prototype.$url_base = url_base
Vue.prototype.$api = axios.create({
  baseURL: url_base
})


// Biblioteca de validacion de controles en formularios
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


// Filtros globales en archivo separado
import filtros from './filtros'
for (let [k, v] of Object.entries(filtros)) {
  Vue.filter(k, v)
}


// Componentes propias importadas globalmente
Vue.component('fecha', () => import('./controls/fecha.vue'))


/*
 * Aplicación principal
 */
import App from './App.vue'

// variables / funciones globales
import Globales from './globales.coffee'
Vue.use(Globales, {})

new Vue({
  router,
  data: {
    token: null,
    usuario: {}
  },
  render: h => h(App),
}).$mount('#app')
