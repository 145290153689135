// rutas para carga de componentes usando Vue Router

const routes = [
  { path: '/', component: () => import('./pages/login.vue') },
  { path: '/nomina-pago', component: () => import('./pages/nomina-pago.vue') },
  { path: '/dte-consulta', component: () => import('./pages/dte-consulta.vue') },
  { path: '/calce-oc-dte', component: () => import('./pages/calce-oc-dte.vue') },
  { path: '/pagos-consulta', component: () => import('./pages/pagos-consulta.vue') },
  { path: '*', component: () => import('./pages/404.vue') },
//  { path: '/', component: DashView, auth: true,
//    children: [
//      { path: '', component: DashboardView, name: 'Dashboard' }, 
//      { path: '/tables', component: TablesView, name: 'Tables' }
//    ]
//  }, 
]

export default routes
