<template lang="pug">
b-container.my-2
  b-row
    b-col
      router-link( to="/dte-consulta" )
        b-icon( icon="file-earmark-ruled" )
        small.ml-1 Consulta de DTE
  b-row
    b-col
      router-link( to="/calce-oc-dte" )
        b-icon( icon="columns-gap" )
        small.ml-1 Calce OC v/s DTE
  b-row
    b-col
      router-link( to="/nomina-pago" )
        b-icon( icon="card-list" )
        small.ml-1 N&oacute;mina de Pago
  b-row
    b-col
      router-link( to="/pagos-consulta" )
        b-icon( icon="cash-stack" )
        small.ml-1 Consulta de Pagos DTE
  b-row
    b-col
      router-link( to="/ajustes" )
        b-icon( icon="gear" )
        small.ml-1 Ajustes
</template>


<script>
export default {
  name: 'menu-principal'
}
</script>
