// filtros para presentación de valores formateados

const filtros = {
  rut: (value) => {
    if (!value) return ''
    value = value.toString()
    value = value.replace(/(\d+)(\d{3})/, '$1.$2')
    value = value.replace(/(\d+)(\d{3})/, '$1.$2')
    return value
  }
}

export default filtros
