##
# Plugin para variables / metodos globales
#
export default

  install: (Vue, options) =>

    # 1. add global method or property
    Vue.myGlobalMethod = ->
      # some code ...


    # 2. add a global asset
    Vue.directive('my-directive', ->
      bind: (el, binding, vnode, oldVnode) ->
        # some code ...
      # ...
    )


    # 3. inject some component options
    Vue.mixin(

      # cuidado con este hook, porque se ejecuta muchisimas veces en cada
      # pantalla (por cada componente que hay en pantalla)
      created: ->
        #console.log("mixin")

      methods:
        # valida que el usuario se haya loggeado y sea un usuario valido (tecnico)
        validarLogin: ->
          if @$route.query.token
            localStorage.setItem('token', @$route.query.token)

          if localStorage.getItem('token')
            @$root.token = localStorage.getItem('token')

          href = "/?ruta=" + location.pathname
          if @$root.token
            r = (await @.$api.get("autenticaciones/" + @$root.token)).data
            if r.correo
              if not @$root.usuario.nombre
                p = (await @.$api.get("personas", params: { correo: r.correo })).data.datos
                x = { nombre: p[0].nombre_completo, correo: p[0].correo }
                @$root.usuario = Object.assign({}, x)
              return
            else if r.error?.indexOf("inexistente") >= 0
              localStorage.removeItem('token')
              href += "&error=inexistente"
            else if r.error?.indexOf("expirado") >= 0
              localStorage.removeItem('token')
              href += "&error=expirado"

          location.href = href

        # carga desde la API Conpax ppal y forma las listas globales:
        # $root.companias, $root.unes, $root.unes_vigentes,
        # $root.personas_vigentes
        cargarGlobales: ->
          if not @$root.companias.length
            @$root.overlay = true
            @.cargarIndex("companias").then (r) =>
              @$root.companias = r

            @.cargarIndex("unes").then (r) =>
              @$root.unes = r
              @.$api.get("vigencias/unes").then (r1) =>
                unes_ids_vigentes = r1.data
                @$root.unes_vigentes = r.filter (x) => x.id in unes_ids_vigentes

            @.$api.get("vigencias/personas").then (r) =>
              personas_ids_vigentes = r.data
              @.cargarIndexPaginado("personas",
                id: personas_ids_vigentes
                orden: 'id'
              ).then (r1) =>
                @$root.personas_vigentes = r1
              @$root.overlay = false
            .catch (e) =>
              @$root.overlay = false

        # carga el index de un controlador de alguna entidad en API Conpax
        cargarIndex: (entidad, parametros={}) ->
          new Promise (resolve, reject) =>
            @.$api_conpax.get(entidad, params: parametros)
            .then (r) =>
              resolve r.data
            .catch (e) =>
              reject e

        # carga async el index de un controlador de alguna entidad en API
        # Conpax, cargando todos los registros recursiva% avanzando pagina
        # por pagina
        cargarIndexPaginado: (entidad, parametros={}, lista=[]) ->
          parametros.pagina ?= 1
          new Promise (resolve, reject) =>
            @.$api_conpax.get(entidad, params: parametros)
            .then (r) =>
              arreglo = lista.concat(r.data.datos)
              if r.data.pagina < r.data.paginas
                parametros.pagina = r.data.pagina + 1
                resolve @.cargarIndexPaginado(entidad, parametros, arreglo)
              else
                resolve arreglo
            .catch (e) =>
              reject e

        # recorre cada control especificado para validaø en 'validations:' y
        # despliega un toast de error unificado para los campos inválidos
        validar: ->
          @.$v.$touch()
          if @.$v.$error
            campos = []
            for elem in @.$v.$flattenParams()
              v = @.$v
              v = v[p] for p in elem.path
              campos.push(p) if v.$error
            campos = campos.filter((x, i, a) => a.indexOf(x) == i) # = Array.uniq
            @.$bvToast.toast("Hay campos vacíos o que presentan errores de ingreso: #{campos.join(', ')}.",
                title: "Ingreso con Errores", variant: "danger")
          not @.$v.$error
        
        # toasts
        "$ok": (titulo, mensaje, duracion=5) ->
          @.$bvToast.toast(mensaje, title: titulo, variant: "success",
              autoHideDelay: duracion * 1000)

        "$warn": (titulo, mensaje, duracion=6) ->
          @.$bvToast.toast(mensaje, title: titulo, variant: "warning",
              autoHideDelay: duracion * 1000)

        "$error": (titulo, mensaje, duracion=8) ->
          @.$bvToast.toast(mensaje, title: titulo, variant: "danger",
              autoHideDelay: duracion * 1000)
    )


    # 4. add an instance method

    # variable global con estados posibles de items @.$estados
    Vue.prototype.$estados = [ "Asignado", "Disponible", "Reparacion", "Externo",
        "Reciclado", "Donado", "Extraviado", "Robado", "Descartado" ]
